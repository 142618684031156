<template>
    <div class="Trainee-TS-modal-overlay">
        <div class="Trainee-TS-modal-dialog" @click.stop>
            <div class="Trainee-TS-modal-content">
                <div class="Trainee-TS-modal-header">
                    <h5 class="Trainee-TS-modal-title" style="padding-top: 10px;"><b>Trainee Answerbook</b> (Task ID: {{
                        taskId }})
                    </h5>
                </div>
                <div class="container-fluid" v-show="currentTab === 'troubleshoot'">
                    <div class="artificial-fieldset">

                        <div class="question">
                            <div>
                                <div class="artificial-fieldset">
                                    <label class="artificial-fieldset-legend">Document Reference Examples</label>
                                    <table class="trainee-info">
                                        <thead>
                                            <th>Doc.</th>
                                            <th>Rev.</th>
                                            <th>Eff.</th>
                                            <th>Task</th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>TSM</td>
                                                <td>Oct 13 / 079</td>
                                                <td>123</td>
                                                <td>System Operational test</td>
                                            </tr>
                                            <tr>
                                                <td>MEL</td>
                                                <td>Jan 14 / 065</td>
                                                <td>123</td>
                                                <td>Deactivation Procedure</td>
                                            </tr>
                                            <tr>
                                                <td>AMM</td>
                                                <td>Jan 14 / 065</td>
                                                <td>123</td>
                                                <td>Remove / Installation</td>
                                            </tr>
                                            <tr>
                                                <td>Job Card</td>
                                                <td>Oct 13 / 079</td>
                                                <td></td>
                                                <td>Tyre Pressure Check</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div class="artificial-fieldset">
                                <label class="artificial-fieldset-legend">Document References</label>
                                <!-- <h6 class="flight-deck-effect"><b>FLIGHT DECK EFFECT</b></h6> -->
                                <table class="trainee-info-table">
                                    <thead>
                                        <th style="width: 19%">Doc.</th>
                                        <th style="width: 25%">Rev.</th>
                                        <th style="width: 9%">Eff.</th>
                                        <th>Task</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in documentReferences" :key="index">
                                            <td><input v-model="item.doc" /></td>
                                            <td><input v-model="item.rev" /></td>
                                            <td><input v-model="item.eff" /></td>
                                            <td><input v-model="item.task" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="trainee-answer-section">
                        <div class="artificial-fieldset">
                            <label class="artificial-fieldset-legend">Question</label>
                            <div ref="editor" class="quill-editor" :class="{ 'read-only': readOnly }"></div>
                        </div>
                    </div>
                    <div class="Trainee-modal-footer">
                        <button @click="close" class="btn btn-secondary"
                            style="margin-top: 15px; margin-bottom: 20px;">Close</button>
                        <div class="right">
                            <button @click="saveAnswers" class="btn btn-primary">Save</button>
                            <button @click="submitAnswers" class="btn btn-primary"
                                style="background-color: brown;">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
    name: 'troubleshootModal',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        },
        troubleshoot: String,
        taskExplanation: String
    },
    data() {
        return {
            documentReferences: [
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' },
                { doc: '', rev: '', eff: '', task: '' }
            ],
            showTroubleshootModal: true,
            editableTroubleshoot: this.troubleshoot,
            currentTab: 'troubleshoot',
            quill: null,
        };
        
    },
    computed: {
        currentQuestion() {
            return this.questions[this.currentQuestionIndex];
        },
        QuestionsCount() {
            return this.questions.length;
        },
    },
    watch: {
        showTroubleshootModal(newValue) {
            if (newValue && this.quill) {
                // Wait for the next tick to ensure the Quill editor is mounted and visible
                this.$nextTick(() => {
                    this.quill.root.innerHTML = this.editableTroubleshoot || '';
                });
            }
        },
        editableTroubleshoot(newVal) {
            if (newVal !== this.quill.root.innerHTML) {
                this.quill.root.innerHTML = newVal;
            }
        },
        currentTab(newVal, oldVal) {
            if (newVal === 'troubleshoot' && oldVal === 'description') {
                this.initializeQuillEditor();
            }
        },
    },

    mounted() {
        this.initializeQuillEditor();
        this.loadAnswers();
    },
    methods: {
        initializeQuillEditor() {
            if (!this.quill) {
                this.quill = new Quill(this.$refs.editor, {
                    theme: 'snow',
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline'],
                            ['blockquote', 'code-block'],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ]
                    }
                });
                

                this.quill.on('text-change', () => {
                    this.editableTroubleshoot = this.quill.root.innerHTML;
                });

                this.quill.root.innerHTML = this.editableTroubleshoot || '';
                this.adjustEditorHeight();
            }
        },
        async loadAnswers() {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            try {
                const response = await axios.get(`/api/logbookentry/${this.logbookId}/${this.taskId}`, { headers });
                const storedAnswer = response.data.task.troubleshoot;
                this.editableTroubleshoot = storedAnswer;
                this.is_submitted = response.data.task.is_submitted;
                const logbookentry_id = response.data.task.id;

                const taskResponse = await axios.get(`/api/tasks/${this.taskId}`, { headers });
                const initialContent = taskResponse.data.initial_content;

                
                const responseDoc = await axios.get(`/api/document-references/${logbookentry_id}`, { headers });

                // Update the documentReferences array with the fetched data
                this.documentReferences = responseDoc.data;

                // If the loaded references are less than 4, fill the array to have at least 4 rows
                while (this.documentReferences.length < 4) {
                    this.documentReferences.push({ doc: '', rev: '', eff: '', task: '' });
                }

                // After loading the answer, update the Quill editor content
                if (this.quill) {
                    this.quill.root.innerHTML = storedAnswer || initialContent;

                }

                if (this.is_submitted === 1) {
                    this.makeEditorReadOnly(); // Apply the read-only state if needed
                }
            } catch (error) {
                console.error('Failed to load answers', error);
                // Handle the error, show a message, etc.
            }
        },
        adjustEditorHeight() {
            const editorElement = this.$refs.editor.querySelector('.ql-editor');
            const toolbar = this.$refs.editor.querySelector('.ql-toolbar');
            const toolbarButtons = this.$refs.editor.querySelectorAll('.ql-toolbar .ql-formats');

            if (editorElement) {
                editorElement.style.height = '360px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';
                toolbar.style.minHeight = '30px';
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';
                });
            }
        },
        close() {
            this.$emit('close');
        },
        saveAnswers() {
            this.saveOrUpdateAnswers(false);
        },
        submitAnswers() {
            if (window.confirm('Once you submit, you cannot edit the answers. Are you sure you want to continue?')) {
                this.saveOrUpdateAnswers(true);
            }
        },
        saveOrUpdateAnswers(isSubmitting) {
            const formattedTroubleshoot = this.quill.root.innerHTML;
            const url = `/api/logbookentry/${this.logbookId}/${this.taskId}`;
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            // Prepare the data to be sent in the request
            const requestData = {
                troubleshoot: formattedTroubleshoot,
            };

// If submitting, include the is_submitted value in the request data
if (isSubmitting) {
    requestData.is_submitted = true;
}

            axios.put(url, requestData, { headers })
                .then(response => {
                    alert('Answer saved successfully.');
                    this.editableTroubleshoot = formattedTroubleshoot;
                    this.$emit('close');

                    if (isSubmitting) {
                        this.makeEditorReadOnly();
                    }

                    // Iterate through documentReferences and save each one
                    this.documentReferences.forEach(item => {
                        const docRequestData = {
                            logbookentry_id: response.data.id,
                            doc: item.doc,
                            rev: item.rev,
                            eff: item.eff,
                            task: item.task
                        };

                        // If the document reference has an 'id', it's an update (PUT request)
                        if (item.id) {
                            axios.put(`/api/doc-ref/${item.id}`, docRequestData, { headers })
                                .then(docResponse => {
                                    console.log('Document reference updated successfully:', docResponse.data);
                                })
                                .catch(error => {
                                    console.error('Failed to update document reference:', error);
                                    alert('Failed to update the document reference.');
                                });
                        } else {
                            // If there is no 'id', it's a new document reference (POST request)
                            axios.post(`/api/doc-ref`, docRequestData, { headers })
                                .then(docResponse => {
                                    console.log('Document reference posted successfully:', docResponse.data);
                                })
                                .catch(error => {
                                    console.error('Failed to post document reference:', error);
                                });
                        }
                    });
                })
                .catch(error => {
                    console.error('There was an error!', error);
                    alert('Failed to save the answer.');
                });
        },
        makeEditorReadOnly() {
            if (this.quill) {
                this.quill.enable(false); // This disables editing
                this.quill.getModule('toolbar').container.style.display = 'none'; // This hides the toolbar
                this.quill.root.setAttribute('contenteditable', false); // This disables the cursor
            }
        },

        prevQuestion() {
            if (this.currentQuestionIndex > 0) {
                this.currentQuestionIndex--;
                // The computed property 'currentQuestion' will automatically update the question displayed
            }

        },
        nextQuestion() {
            if (this.currentQuestionIndex < this.questions.length - 1) {
                this.currentQuestionIndex++;
            }
        }
    }
}
</script>
 


<style>
.trainee-info-table input {
    border: 1px solid lightgray;
    border-radius: 5px;
    height: 30px;
    outline: none;
    /* Optional: Remove the outline when the input is focused */
    margin: 0px;
    width: 100%;
}

table.trainee-info {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.trainee-info th,
.trainee-info td {
    border: 1px solid #bbbbbb;
    text-align: left;
    padding: 3px;
    font-size: 14px;
}

.trainee-info td {
    font-weight: 100;
}

.trainee-info th {
    background-color: #ebebeb;
}

.question p {
    font-size: 14px;
    /* Adjust the font size as desired */
    font-weight: lighter;
    /* Adjust the font weight as desired */
}

table.trainee-info-table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
}

.trainee-info-table th,
.trainee-info-table td {
    border: 1px solid #bbbbbb;
    text-align: left;
    font-size: 14px;
}

.trainee-info-table td {
    font-weight: 100;
}

.trainee-info-table th {
    background-color: #ebebeb;
    padding: 4px;
}

.Trainee-TS-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(97, 94, 94, 0.5);
    z-index: 1000;
    JUSTIFY-CONTENT: CENTER;
    overflow-y: auto;
}

.Trainee-TS-modal-body h2 {
    list-style-type: none;
    margin-top: 10px;
    padding: 0;
    margin-right: 1%;
    text-align: center;
    overflow-y: auto;

}

.Trainee-TS-modal-body ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 5%;
}

.Trainee-TS-modal-body p.heading {
    list-style-type: none;
    margin-top: 20px;
    text-align: left;
    margin-left: 1%;
}

.assessment-criteria-box {
    border: 1px solid #b1b1b1;
    /* You can adjust the border color and thickness */
    padding: 10px;
    /* Optional: Add padding for better appearance */
    margin: 10px 0;
    /* Optional: Add margin for spacing */
}


.Trainee-TS-modal-content {
    max-width: 1000px;
    width: 100%;
    margin: auto;
    padding: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 84vh;
    margin-top: 40px;
}

.Troubleshoot-modal-dialog {
    display: flex;
    flex-direction: row;
    /* Arrange children (questions and answers) in a row */
}

.Trainee-TS-modal-dialog {
    max-height: 84vh;
    overflow-y: auto;
}

.Troubleshoot-modal-body {
    display: flex;

}

.question-item {
    margin: 10px 0;
    text-align: left;
    font-weight: bold;
}

.Trainee-questions-container>ol>li>ul {
    list-style-type: disc;
    /* Adds a bullet point (dot) */
    padding-left: 40px;
    /* Indent subpoints further than the main list */
}

.Trainee-questions-container ul li {
    margin: 5px 0;
}

.Trainee-questions-container {
    text-align: left;
}

button {
    margin-top: 20px;
    /* Adjust as needed */
    width: 100px;
    /* Adjust as needed */
    padding: 10px;
    /* Adjust as needed */
    background-color: #f2f2f2;
    /* Style as needed */
    border: none;
    /* Style as needed */
    cursor: pointer;
    /* Style as needed */
    /* Add more styling as needed */
}

.questions-list {
    text-align: left;
}

.Trainee-questions-container h6,
.Trainee-answers-container h6 {
    width: 100%;
    background-color: rgb(216, 216, 216);
    /* Light grey background */
    /* Border for the header */
    text-align: center;

}


.list-group-item {
    background-color: transparent;
    /* Remove default background */
}

.list-group-numbered {
    counter-reset: list-group-counter;
    text-align: left;
}

.list-group-numbered>.list-group-item:before {
    content: counter(list-group-counter) ". ";
    counter-increment: list-group-counter;
}

/* Style for the nested ul */
.Trainee-questions-container ul {
    list-style-type: none;
    /* Removes bullet points */
    padding-left: 20px;
    /* Indent subpoints */
}

.Trainee-answers-container {
    outline: none;
    z-index: 1000;
    width: 60%;
}

.Trainee-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Trainee-modal-footer .right {
    margin-top: 1%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    /* Adjust the gap between buttons */
}

.nav-link {
    cursor: pointer;
}

.artificial-fieldset {
    border: 1px solid #8b8b8b;
    padding: 10px;
    margin-top: 20px;
    position: relative;
}

.artificial-fieldset-legend {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    padding: 0 10px;
    font-weight: bold;
}


.question {
    text-align: left;
    font-weight: bold;
}

.navigation-buttons {
    margin-top: 5px;
}

.nav-button {
    padding: 5px 15px;
    /* Adjust padding to change button size */
    margin: 0 5px;
    /* Space between buttons */

}


.ql-toolbar {
    height: 40px;
    min-height: 40px;
}


.ql-toolbar .ql-formats {
    margin: 0 2px;
}

.ql-container .ql-editor {
    height: 300px;
    min-height: 200px;
}

h4 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
}</style>
<template>
    <div>

    </div>
</template>  
<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
    methods: {
        generatePDF(tasks, logbookName) {
            this.traineeName = localStorage.getItem('username');
            this.instructorName = localStorage.getItem('instructorName');
            this.traineeId = localStorage.getItem('user_id');
            this.instructorId = localStorage.getItem('instructor_id');
            this.productName = localStorage.getItem('productName');

            const doc = new jsPDF();

            // Add Logbook Name as heading
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(16);
            doc.text(logbookName, 10, 10);

            // Grey outlined box
            const boxStartY = 15;
            const boxHeight = 30;
            doc.setDrawColor(200); // Gray outline
            doc.setFillColor(240, 240, 240); // Light gray fill
            doc.rect(10, boxStartY, 190, boxHeight, 'FD'); // Filled box

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(10);
            // Add Trainee and Instructor details within the box
            // Product Name title
            doc.setFont('helvetica', 'bold');
            doc.text("Product Name:", 12, 23);

            // Product Name content
            doc.setFont('helvetica', 'normal');
            doc.text(this.productName, 50, 23);  // Adjust the x-position as per your requirement

            // Trainee title
            doc.setFont('helvetica', 'bold');
            doc.text("Trainee:", 12, 31);

            // Trainee content
            doc.setFont('helvetica', 'normal');
            doc.text(this.traineeName, 50, 31);  // Adjust the x-position as per your requirement

            // Trainee ID title
            doc.setFont('helvetica', 'bold');
            doc.text("Trainee ID:", 80, 31);

            // Trainee ID content
            doc.setFont('helvetica', 'normal');
            doc.text(this.traineeId, 110, 31);  // Adjust the x-position as per your requirement

            // Instructor title
            doc.setFont('helvetica', 'bold');
            doc.text("Instructor:", 12, 38);

            // Instructor content
            doc.setFont('helvetica', 'normal');
            doc.text(this.instructorName, 50, 38);  // Adjust the x-position as per your requirement

            // Instructor ID title
            doc.setFont('helvetica', 'bold');
            doc.text("Instructor ID:", 80, 38);

            // Instructor ID content
            doc.setFont('helvetica', 'normal');
            doc.text(this.instructorId, 110, 38);  // Adjust the x-position as per your requirement

            doc.setFont('helvetica', 'bold');
            doc.setFontSize(8);
            const columns = ['Task No', 'module', 'chapt_no', 'location', 'AMTOSS', 'category', 'level', 'activity', 'completed', 'ins sign off'];
            const filteredTasks = tasks.filter(task => task.completed && task.completed.trim() !== "");

            const rows = filteredTasks.map(task => [
                task.task_no,
                task.module,
                task.chapt_no,
                task.location,
                task.AMTOSS,
                task.category,
                task.level,
                task.activity,
                task.completed,
                task.ins_so,
            ]);

            doc.autoTable(columns, rows, {
                startY: boxStartY + boxHeight + 5, // start below the box with a small gap
                styles: { overflow: 'linebreak' },
                headStyles: {
                    fontSize: 6  // Adjust font size for the column headers
                },
                bodyStyles: {
                    fontSize: 7  // font size for the table body data
                },
                columnStyles: { text: { cellWidth: '5px' } },
                margin: { horizontal: 10 }
            });

            const blob = doc.output('blob');

            // Create an Object URL from the blob and open it in a new tab
            const pdfURL = URL.createObjectURL(blob);
            window.open(pdfURL, '_blank');
        }
    }
}
</script>

  
  
<style scoped>
h1.page-title {
    text-align: left;
    padding-top: 100px;
    font-size: 22px;
    margin-left: 3%;
    padding-bottom: 10px;
}
</style>

<template>
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  </head>
  <Header />
  <div v-if="isOffline" class="offline-box">
    <p>Offline Mode</p>
  </div>
  <div v-if="isSyncing" class="syncing-box">
    <p>You're online! Syncing data...</p>
  </div>
  <div class="table-section-trainee">
    <table class="logbook-name">
      <td class="logbookName" style="border:none;">{{ logbookName }}</td>
      <td> <button @click="openTraineeProgressModal" style="font-size: 18px; margin-right: 20px; " class="pdfButton">Progress</button></td>
      <!--<button @click="openPopup($event)" class="progress-popup">Progress</button>-->
      <td style="border:none;"><button class="signatureButton" @click="openSignatureModal">Signature</button>
      </td>
      <td style="border:none"> <button @click="createPDFReport" style="font-size: 18px;" class="pdfButton">Download
          PDF</button></td>
      
    </table>


    <div class="tee-pagination-button-container">
      <div class="tee-pagination-container">
        <Pagination :total-pages="totalPages" :per-page="perPage" :current-page="currentPage"
          @page-change="onPageChange" />
      </div>
      <div class="tee-pagination-info">
        Showing {{ startIndex + 1 }}-{{ endIndex }} of {{ filteredTasks.length }} Tasks
      </div>

    </div>
    <table class="data-table-trainee">
      <thead>
        <tr>
          <th class="task-no">Task No</th>
          <!--<th class="reference">Reference</th>-->
          <th class="module">Module
            <select v-model="selectedmodule">
              <option value="All">All</option>
              <option v-for="module in uniquemodules" :key="module" :value="module">{{ module }}</option>
            </select>
          </th>
          <th class="chapt-id">Chapt No
            <select v-model="selectedChapter">
              <option value="All">All</option>
              <option v-for="chapter in uniqueChapters" :key="chapter" :value="chapter">{{ chapter }}</option>
            </select>
          </th>
          <th class="level">Location
            <select v-model="selectedLocation">
              <option value="All">All</option>
              <option v-for="location in uniqueLocations" :key="location" :value="location">{{ location }}</option>
            </select>
          </th>
          <th class="AMTOSS">AMTOSS<br>
            <select v-model="selectedAMTOSS">
              <option value="All">All</option>
              <option v-for="AMTOSS in uniqueAMTOSS" :key="AMTOSS" :value="AMTOSS">{{ AMTOSS }}</option>
            </select>
          </th>
          <th class="area_id">Area
            <select v-model="selectedArea">
              <option value="All">All</option>
              <option v-for="area in uniqueAreas" :key="area" :value="area">{{ area }}</option>
            </select>
          </th>
          <th class="activity">Activity</th>
          <th class="completed">Completed
            <select v-model="selectedCompleted" style="width:100%">
              <option value="All tasks">All tasks</option>
              <option value="completed tasks">Completed tasks</option>
              <option value="not completed tasks">Not completed tasks</option>
            </select>
          </th>
          <th class="inssign">Inst. Sign Off
            <select v-model="selectedSignOff" style="width:100%">
              <option value="All tasks">All tasks</option>
              <option value="signed off tasks">Signed Off</option>
              <option value="not signed off tasks">Not Signed Off</option>
            </select>
          </th>
          <th class="trainee_notes">My Notes</th>
          <th class="trainee_insnotes">Inst Notes</th>
          <th class="assess">Assess</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(task, index) in [...paginatedTasks, ...offlineTasks]" :key="index">
          <td @click="openTaskLongFormModal(task)">{{ task.task_no }}</td>
          <td>{{ task.module }}</td>
          <td>{{ task.chapt_no }}</td>
          <td>{{ task.location }}</td>
          <td>{{ task.AMTOSS }}</td>
          <td>{{ task.area }}</td>
          <!-- <td @click="openTaskDescriptionModal(task)"
            :class="{ 'Description-data': task.is_exam, 'Other-description': !(task.is_exam) }">
            {{ task.activity }}
          </td> -->
          <td>
              <div @click="openTaskDescriptionModal(task)" :style="{ color: task.is_assessed ? 'green' : 'blue' }">
                {{ task.activity }}
              </div>
              <div v-if="task.is_exam" :style="{ color: task.is_assessed ? 'green' : 'red' }">
                <b>{{ task.is_assessed ? '*Assessed' : (task.being_assessed ? '*Selected for Assessment' : '*Examinable')}}</b>
              </div>
            </td>
          <!--<td>{{ task.sect_id }}</td>
          <td>{{ task.level }}</td>
          <td>{{ task.is_exam }}</td>
          <td>{{ task.enabled }}</td> -->
          <td>
            <div v-if="task.completed">
              {{ task.completed }}
              <!-- <img :src="require('@/components/Trainee/sign_sample.png')" class="image_sample" alt="Signature" /> -->
              <p v-if="task.se_so" class="se_so" title="by Joe Smith at 13:54:21">
      <i v-if="task.se_so" class="fas fa-check"></i> SE Signed Off 
    </p>
              <button class="se_signoff" @click.prevent="openQRCode()" v-if="task.completed && !task.is_assessed">SE Sign Off</button>
            </div>
            <div v-else>
              <button class="trainee_signoff" @click.prevent="confirmSignOff(task)" :disabled="!task.enabled">SIGN
                OFF</button>
            </div>
          </td>
          <td>
            <div>
              {{ task.ins_so }}
            </div>
          </td>
          <td>
            <button class="notesButton" @click="openEditor(task.task_id)">
              <i :class="{
                'fas fa-sticky-note': true, 'icon-green': allTraineeNotes[task.task_id] && allTraineeNotes[task.task_id].trim() !== '',
                'icon-grey': !allTraineeNotes[task.task_id] || allTraineeNotes[task.task_id].trim() === ''
              }"></i>
            </button>
          </td>
          <td>
            <button class="notesButton" @click="openNotesModal(task.task_id)">
              <i
                :class="{ 'fas fa-eye': true, 'icon -green': allPublicNotes[task.task_id], 'icon-grey': !allPublicNotes[task.task_id] }"></i>
            </button>
          </td>
          <td v-if="task.is_assessed">
  <a style="text-decoration: underline; color: blue; cursor: pointer;">View Results</a>
</td>
<td v-else>
</td>
        </tr>
      </tbody>
    </table>

    <div class="evaluationSection">
      <!-- <button class="evaluationButton" @click="openDocModal()">Document Reference</button> -->
      <button class="evaluationButton" @click="openEvaluationDisplayModal()" :disabled="!evaluationIsSubmitted">
        Evaluation Sheet</button>
    </div>
    <QuillEditor v-if="showQuillEditor" :task-id="currentTaskId" :initialContent="getCurrentTaskNotes"
      :displayTaskId="currentTaskId" @saveNotes="closeQuillEditor">
    </QuillEditor>
    <troubleshootModal v-if="showTroubleshootModal" :task-id="currentTaskId" :logbook-id="logbookId"
      :troubleshoot="currentTroubleshoot" :task-explanation="currentTaskExplanation" @close="closeTroubleshootModal">
    </troubleshootModal>
    <TaskDescriptionModal v-if="showTaskDescriptionModal" :task-id="currentTaskId" :logbook-id="logbookId"
      :task-explanation="currentTaskExplanation" @close="closeTaskDescriptionModal"></TaskDescriptionModal>
      <TaskLongFormModal v-if="showTaskLongFormModal" :task-id="currentTaskId" :logbook-id="logbookId"
       @close="closeTaskLongFormModal"></TaskLongFormModal>
       <TraineeProgressModal v-if="showTraineeProgressModal" :logbook-id="logbookId"
       @close="closeTraineeProgressModal"></TraineeProgressModal>
    <EvaluationDisplay v-if="showEvaluationDisplayModal" :tasks-count="tasksCount" :completed-tasks="tasksCompleted"
      :evaluation-id="evaluationId" :logbook-id="logbookId" :fetched-data="fetchedEvaluationData"
      @close="closeEvaluationDisplayModal"></EvaluationDisplay>
    <QrcodeModal v-if="showQRCode" :task-id="currentTaskId" :logbook-id="logbookId" @close="closeQRCode"></QrcodeModal>
    <signature-modal v-if="showSignature" @close="closeSignature"></signature-modal>
    <TaskCountsPopup ref="modal" :completedTasks="countOfCompletedTasks" :totalTasks="countOfTotalTasks"
      :enabledTasks="countOfEnabledTasks" v-if="showPopup" @close="closePopup" />
    <document-reference-modal v-if="showDocModal" @update:showModal="showDocModal = $event" @close="closeDocModal" />
    <div v-if="showNotesModal" class="notes-modal-overlay" @click="closeNotesModal">
      <div class="notesModal" @click.stop>
        <div class="notes-modal-content">
          <h5>Task ID: {{ currentTaskId }}</h5>
          <hr>
          <p>{{ currentNote }}</p>
        </div>
      </div>
    </div>
    <div v-if="showNewQuillEditor" class="quill-modal-overlay" @click="showNewQuillEditor = false">
      <div class="quill-modal" @click.stop>
        <div>
          <h3 style="text-align: left;"> Trainee Answer Book</h3>
          <h6 style="font-weight: bold;">Task ID: {{ currentTaskId }}</h6>
          <!-- WysiwygEditor Component with predefined content -->
          <wysiwyg-editor></wysiwyg-editor>
          <div class="button-container">
            <!-- Save Button -->
            <button class="save-button" style="background-color: rgb(14, 51, 13); color:white"
              @click="saveAnswers">Save</button>

            <!-- Submit Button -->
            <button class="submit-button" style="background-color: rgb(2, 58, 7); color:white"
              @click="submitAnswers">Submit</button>

            <!-- Close Button -->
            <button class="close-button" style="background-color: rgb(51, 13, 13); color:white"
              @click="closeEditor">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './TraineeHeader.vue';
import axios from 'axios';
import './TraineeLogbook.css';
import QuillEditor from '@/components/notes/quill.vue';
import Pagination from '@/components/Examiner/TablePagination.vue';
import LogbookReport from './TraineeLogbookReport.vue';
import troubleshootModal from '@/components/Trainee/troubleshootModal.vue';
import TaskDescriptionModal from '@/components/Trainee/TaskDescriptionModal.vue';
import TaskLongFormModal from '@/components/Trainee/TaskLongFormModal.vue';
import TraineeProgressModal from '@/components/Trainee/TraineeProgressModal.vue';
import EvaluationDisplay from './EvaluationDisplayModal.vue';
import QrcodeModal from '@/components/Trainee/QrcodeModal.vue';
import Localbase from 'localbase';
import SignatureModal from '@/components/Examiner/TraineeSignature.vue';
import DocumentReferenceModal from './DocumentReferenceModal.vue';
import WysiwygEditor from './wysiwygModal.vue';
import TaskCountsPopup from './TaskCountsPopup.vue';

let db = new Localbase('myDatabase');


export default {
  name: 'TraineeLogbook',
  components: {
    Header,
    QuillEditor,
    troubleshootModal,
    TaskDescriptionModal,
    TaskLongFormModal,
    TraineeProgressModal,
    EvaluationDisplay,
    Pagination,
    SignatureModal,
    DocumentReferenceModal,
    QrcodeModal,
    WysiwygEditor,
    TaskCountsPopup,
  },
  data() {
    return {
      allPossibleColumns: ['task_no', 'ratings', 'module', 'chapt_no', 'location', 'area', 'activity', 'category', 'AMTOSS', 'level', 'completed', 'inssign', 'trainee_notes', 'trainee_insnotes', 'assess'],
      selectedColumns: [],
      showDropdown: false,
      logbooks: [],
      tasks: [],
      logbookName: '',
      productId: localStorage.getItem('productId') || null,
      logbookId: localStorage.getItem('logbook_id'),
      mergedTasks: [],
      editorContent: '',
      showSignature: false,
      showNewQuillEditor: false,
      showTroubleshootModal: false,
      showTaskDescriptionModal: false,
      showTaskLongFormModal: false,
      showTraineeProgressModal: false,
      showEvaluationDisplayModal: false,
      showDocModal: false,
      evaluationIsSubmitted: false,
      fetchedEvaluationData: null,
      allTraineeNotes: {},
      notes: {},
      currentTaskId: null,
      notesContent: '',
      isLoadingNote: false,
      allPublicNotes: {},
      showNotesModal: false,
      currentNote: '',
      notesExist: false,
      showPopup: false,
      selectedAMTOSS: 'All',
      selectedmodule: 'All',
      selectedCompleted: 'All tasks',
      selectedLevel: 'All',
      selectedLocation: 'All',
      selectedscope: 'All',
      selectedChapter: 'All',
      selectedArea: 'All',
      selectedCategory: 'All',
      selectedisexam: 'All',
      selectedSignOff: 'All tasks',
      offlineTasks: [],
      isSyncing: false,
      isOffline: !navigator.onLine,
      currentTroubleshoot: '',
      filteredTasksLength: 0,
      currentPage: 1,
      perPage: 12,
      showQRCode: false,
      modalStyle: { // Add a new reactive property for the modal style
        display: 'none', // Default to none
        position: 'absolute',
        top: '0px',
        left: '0px',
      },
    };
  },
  computed: {
    getCurrentTaskNotes() {
      if (!this.currentTaskId) return '';
      return this.allTraineeNotes[this.currentTaskId] || '';
    },
    hasAssessedTasks() {
    return this.mergedTasks.some(task => task.is_assessed);
  },
    filteredTasks() {
      return this.mergedTasks.filter(task => {
        const completedMatches =
          this.selectedCompleted === 'All tasks' ||
          (this.selectedCompleted === 'completed tasks' && task.completed) ||
          (this.selectedCompleted === 'not completed tasks' && !task.completed);
        const SignedOffMatches =
          this.selectedSignOff === 'All tasks' ||
          (this.selectedSignOff === 'signed off tasks' && task.ins_so) ||
          (this.selectedSignOff === 'not signed off tasks' && !task.ins_so);
        const levelMatches = this.selectedLevel === 'All' || task.level === parseInt(this.selectedLevel);
        const areaMatches = this.selectedArea === 'All' || task.area === this.selectedArea;
        const scopeMatches = this.selectedscope === 'All' || task.scope === this.selectedscope;
        const chapterMatches = this.selectedChapter === 'All' || task.chapt_no === this.selectedChapter;
        const categoryMatches = this.selectedCategory === 'All' || task.category === this.selectedCategory;
        const isexamMatches = this.selectedisexam === 'All' || task.is_exam === this.selectedisexam;
        const locationMatches = this.selectedLocation === 'All' || task.location === this.selectedLocation;
        const amtossMatches = this.selectedAMTOSS === 'All' || task.AMTOSS === this.selectedAMTOSS;

        return completedMatches && SignedOffMatches  &&  levelMatches && areaMatches && scopeMatches && chapterMatches && categoryMatches && isexamMatches && locationMatches && amtossMatches;
      });
    },
    uniqueChapters() {
      const chapters = this.tasks.map(task => task.chapt_no);
      return [...new Set(chapters)]; // This will remove any duplicates and return unique values.
    },
    uniqueLevels() {
      const levels = this.tasks.map(task => task.level);
      return [...new Set(levels)];
    },
    uniquescopes() {
      const scopes = this.tasks.map(task => task.scope);
      return [...new Set(scopes)];
    },
    uniqueAMTOSS() {
      const AMTOSS = this.tasks.map(task => task.AMTOSS);
      return [...new Set(AMTOSS)];
    },
    uniquecategories() {
      const categories = this.tasks.map(task => task.category);
      return [...new Set(categories)];
    },
    uniqueisexam() {
      const isexams = this.tasks.map(task => task.is_exam);
      return [...new Set(isexams)];
    },
    uniqueAreas() {
      const areas = this.tasks.map(task => task.area);
      return [...new Set(areas)];
    },
    uniqueLocations() {
      const locations = this.tasks.map(task => task.location);
      return [...new Set(locations)];
    },
    totalPages() {
      return Math.ceil(this.filteredTasks.length / this.perPage);
    },
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      const end = this.startIndex + this.perPage;
      return end < this.filteredTasks.length ? end : this.filteredTasks.length;
    },
    paginatedTasks() {
      const start = this.startIndex;
      const end = this.endIndex;
      return this.filteredTasks.slice(start, end);
    },
    countOfCompletedTasks() {
      return this.mergedTasks.filter(task => task.completed).length;
    },

    countOfTotalTasks() {
      return this.mergedTasks.length;
    },

    countOfEnabledTasks() {
      return this.mergedTasks.filter(task => task.enabled).length;
    },
  },
  ref: {
    logbookReport: LogbookReport
  },
  methods: {
    openPopup(event) {
      const buttonRect = event.target.getBoundingClientRect();

      this.modalStyle.top = `${buttonRect.bottom + window.scrollY}px`;
      this.modalStyle.left = `${buttonRect.left + window.scrollX}px`;
      this.modalStyle.display = 'flex';
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    openQuillEditor(taskId) {
      this.showNewQuillEditor = true;
      this.currentTaskId = taskId.task_id;
    },
    saveAnswers() {
      // Perform the save operation here
      // Assuming the save operation is successful
      window.alert('Answers saved successfully');
    },

    // Method to handle Submit button click
    submitAnswers() {
      // Display a confirmation message before submission
      const confirmation = window.confirm('You won\'t be able to edit them after submission. Continue?');
      if (confirmation) {
        // Perform the submission operation here if confirmed
      }
    },

    // Method to handle Close button click
    closeEditor() {
      // Display a confirmation message before closing
      const confirmation = window.confirm('Any unsaved changes will be lost. Continue?');
      if (confirmation) {
        // Perform the close operation here if confirmed
      }
    },
    openDocModal() {
      this.showDocModal = true;
    },
    closeDocModal() {
      this.showDocModal = false;
    },
    openSignatureModal() {
      this.showSignature = true;
    },
    closeSignature() {
      this.showSignature = false; // Close the modal
    },
    openQRCode() {
      this.showQRCode = true;
    },
    closeQRCode() {
      this.showQRCode = false; // Close the modal
    },
    goToSignature() {
      this.$router.push({ name: 'TraineeSignature' });
    },
    async setTimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.completed = formattedDate;
      task.isReadOnly = true;

      const logbookId = this.$route.params.logbookId;

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('completedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          task_no: task.task_no,
          completed: formattedDate,
          logbookId: logbookId,
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            completed: formattedDate
          }, { headers });
          console.log("success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    async setSETimestamp(task) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      task.se_so = formattedDate;
      task.isReadOnly = true;

      const logbookId = this.$route.params.logbookId;

      if (!navigator.onLine) {
        // Save to Localbase when offline
        db.collection('completedTasks').doc(String(task.task_id)).set({
          taskId: String(task.task_id),  // Convert task_id to string
          task_no: task.task_no,
          se_so: formattedDate,
          logbookId: logbookId,
        }).then(() => {
          console.log('Task saved offline');
        });
      } else {
        try {
          const token = localStorage.getItem('token');
          const headers = { Authorization: `Bearer ${token}` };
          const response = await axios.put(`/api/logbookentry/${logbookId}/${task.task_id}`, {
            se_so: formattedDate
          }, { headers });
          console.log("success in updating logbook entry:", response);
          // Handle the response if needed (e.g. check if the update was successful)
        } catch (error) {
          console.error("Error updating logbook entry:", error);
        }
      }
    },
    onPageChange(newPage) {
      this.currentPage = newPage;
    },

    async syncData() {
      // Check if there's an internet connection
      if (navigator.onLine) {
        this.isSyncing = true;

        const tasksToSync = await db.collection('completedTasks').get();
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        if (tasksToSync.length === 0) {
          this.isSyncing = false;
          return; // exit function if there are no tasks to sync
        }

        let allTasksSynced = true;

        // Synchronize each task's completed status with the backend
        for (const task of tasksToSync) {
          try {
            const response = await axios.put(`/api/logbookentry/${task.logbookId}/${task.taskId}`, {
              completed: task.completed
            }, { headers });

            console.log("success in syncing task:", response);

            // If sync is successful, remove task from Localbase
            await db.collection('completedTasks').doc(task.taskId).delete();

          } catch (error) {
            console.error('Error syncing task:', error);
            allTasksSynced = false;
          }
        }

        this.isSyncing = false;

        if (allTasksSynced) {
          location.reload();
        }
      } else {
        console.log("No internet connection. Data sync postponed.");
      }
    },
    async fetchOfflineTasks() {
      if (!navigator.onLine) {
        try {
          this.offlineTasks = await db.collection('completedTasks').get();
          console.log(this.offlineTasks); // Log the offline tasks
        } catch (error) {
          console.error("Error fetching offline tasks:", error);
        }
      }
    },

    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    async fetchData() {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const logbookId = localStorage.getItem('logbook_id');

        const [logbookResponse, logbookEntryResponse, tasksResponse] = await Promise.all([
          axios.get(`/api/logbooks/${logbookId}`, { headers }),
          axios.get(`/api/logbookentry/${logbookId}`, { headers }),
          axios.get(`/api/${logbookId}/tasks`, { headers })
        ]);

        this.logbookName = logbookResponse.data.name;
        this.tasks = tasksResponse.data.tasks;
        this.logbookEntries = logbookEntryResponse.data.tasks;
        console.log("logbookentries", logbookEntryResponse)
        this.mergeData();
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    },

    mergeData() {
      this.mergedTasks = this.tasks.map(task => {
        const matchingEntry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
        const offlineTask = this.offlineTasks.find(off => off.task_no === task.task_no);
        console.log("offline task", offlineTask)
        return {
          ...task,
          completed: matchingEntry ? matchingEntry.completed : (offlineTask ? offlineTask.completed : null),
          se_so: matchingEntry ? matchingEntry.se_so : (offlineTask ? offlineTask.se_so : null),
          ins_so: matchingEntry ? matchingEntry.ins_so : null,
          enabled: matchingEntry ? (matchingEntry.enabled === 1) : (offlineTask ? 1 : 0),
        };
      });
    },

    shouldShowSignOffButton(task) {
      return (
        !task.complete && // Check if complete is empty (not signed off)
        task.work_order_no !== null &&
        task.work_order_no.toString().trim() !== '' &&
        task.task_detail !== null &&
        task.task_detail.toString().trim() !== '' &&
        task.category !== null &&
        task.category.toString().trim() !== '' &&
        task.ATA !== null &&
        task.ATA.toString().trim() !== ''
      );
    },
    updateCurrentTime() {
      const now = new Date();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      this.currentTime = `${hours}:${minutes}:${seconds}`;
    },
    async fetchAllNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      axios.get(`/api/trainee-notes`, { headers })
        .then(response => {
          console.log('Fetched all notes:', response.data);

          // Assuming response.data is an array of notes
          response.data.forEach(note => {
            this.allTraineeNotes[note.task_id] = note.content;
          });
        })
        .catch(error => {
          console.error("Error fetching all notes:", error);
        });
    },
    async saveNotes(taskId, content) {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      console.log("task, content", taskId, content)
      if (this.notes[taskId]) {
        // Update note
        axios.put(`/api/traineeNotes/${taskId}`, { content }, { headers })
          .then(response => {
            this.notes[taskId] = response.data.content; // Update the local notes object
          })
          .catch(error => {
            console.error('Error updating note:', error);
          });
      } else {
        // Create new note
        const userId = localStorage.getItem('trainee_id');
        axios.post('/api/trainee-notes', { task_id: taskId, content, user_id: userId }, { headers })
          .then(response => {
            this.notes[taskId] = response.data.content; // Update the local notes object
            console.log("notes", this.notes[taskId])
          })
          .catch(error => {
            console.error('Error creating note:', error);
          });
      }
    },
    async openEditor(taskId) {
      this.currentTaskId = taskId;
      this.showQuillEditor = true;
    },
    closeQuillEditor(payload) {
      this.saveNotes(payload.taskId, payload.content);
      this.showQuillEditor = false;
    },

    async fetchAllPublicNotes() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      // Adjust the endpoint to fetch all public notes (not specific to a taskId)
      const endpoint = `/api/instructor-public-notes/`;
      console.log("Fetching all public notes");

      try {
        const response = await axios.get(endpoint, { headers });
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach(note => {
            this.allPublicNotes[note.task_id] = note.content;
          });
        }
      } catch (error) {
        console.error("Error fetching all public notes:", error);
      }
    },
    openNotesModal(taskId) {
      this.currentTaskId = taskId;
      this.notesExist = !!this.allPublicNotes[taskId];
      this.currentNote = stripHtml(this.allPublicNotes[taskId]) || "No Instructor Notes for this task";
      this.showNotesModal = true;
      console.log("notes: ", this.allPublicNotes[taskId]);
    },
    closeNotesModal() {
      this.showNotesModal = false;
    },
    openTroubleshootModal(task) {
      if (!task.enabled) {
        alert('This task is not enabled.');
        return; // Exit the function early
      }
      this.showTroubleshootModal = true;
      const entry = this.logbookEntries.find(entry => entry.task_id === task.task_id);
      this.currentTaskExplanation = task.task_explanation;
      if (entry) {
        this.currentTaskId = task.task_id;  // Make sure 'task_id' is the correct property name
        this.currentTroubleshoot = entry.troubleshoot ? entry.troubleshoot.replace(/<br\s*\/?>/gi, '\n') : '';
      } else {
        console.error('No logbook entry found for task id:', task.task_id);
        this.currentTroubleshoot = '';  // Set to empty string if not found
      }
    },
    closeTroubleshootModal() {
      this.showTroubleshootModal = false;
    },
    openTaskDescriptionModal(task) {
      if (!task.enabled) {
        window.alert('The task must be enabled first');
        return;
      } if (task.is_exam) {
        this.openTroubleshootModal(task);
      }else {
        this.currentTaskId = task.task_id;
        this.currentTaskExplanation = task.task_explanation;
        this.showTaskDescriptionModal = true;
      }
    },
    closeTaskDescriptionModal() {
      this.showTaskDescriptionModal = false;
    },
    openTaskLongFormModal(task) {
        this.currentTaskId = task.task_id;
        this.showTaskLongFormModal = true;
    },
    closeTaskLongFormModal() {
      this.showTaskLongFormModal = false;
    },
    openTraineeProgressModal() {
        this.showTraineeProgressModal = true;
    },
    closeTraineeProgressModal() {
      this.showTraineeProgressModal = false;
    },
    goHome() {
      this.$router.push({ name: 'TraineeHome' }) // Go back to the home page
    },
    confirmSignOff(task) {
      if (confirm('Are you sure you want to sign off?')) {
        this.setTimestamp(task);
      }
    },

    async createPDFReport() {
      const logbookName = this.logbookName;
      LogbookReport.methods.generatePDF(this.filteredTasks, logbookName);
    },
    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
    },
    fetchEvaluationData() {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };
      const logbookId = this.logbookId;

      axios.get(`/api/evaluations?logbook_id=${logbookId}`, { headers })
        .then(response => {
          const evaluation = response.data[0];
          if (evaluation) {
            this.evaluationId = evaluation.id; // Store the fetched evaluation ID
            this.evaluationIsSubmitted = evaluation.is_submitted > 0;
            this.fetchedEvaluationData = evaluation; // Pass the evaluation data directly
            console.log(this.fetchedEvaluationData)
          } else {
            this.evaluationIsSubmitted = false;
          }
        })
        .catch(error => {
          console.error('There was an error fetching the evaluation:', error);
        });
    },

    openEvaluationDisplayModal() {
      this.showEvaluationDisplayModal = true;
      this.tasksCount = this.tasks.length;
      this.tasksCompleted = this.logbookEntries?.completed?.length || 0;
      this.fetchEvaluationData();
    },
    closeEvaluationDisplayModal() {
      this.showEvaluationDisplayModal = false;
    }
  },
  async mounted() {
    this.updateCurrentTime();
    setInterval(this.updateCurrentTime, 1000);
    this.$route.params.logbookId;
    this.$route.params.productId;
    window.addEventListener('online', this.syncData);
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.fetchOfflineTasks();
    this.fetchData();
    this.fetchAllNotes();
    await this.fetchAllPublicNotes();
    this.fetchEvaluationData();
  },
  beforeMount() {
    // Remove the event listener when the component is destroyed
    window.removeEventListener('online', this.syncData);
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
}

function stripHtml(html) {
  if (!html) return "";
  return html.replace(/<\/?[^>]+(>|$)/g, "");
}

</script>

<style scoped>
button.se_signoff {
  margin: 0;
  background-color: rgb(202, 227, 248);
  padding: 1px;
  border-radius: 5px;
  border: 1px solid rgb(176, 203, 226);
}

button.se_signoff:hover {
  background-color: rgb(138, 191, 235);
}

button.signature-button {
  padding: 10px 10px;
  font-size: 16px;
  width: 180px;
  height: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 20px;
  border: 1px solid rgb(0, 96, 134);
  background: #2952c4;
  color: white;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 5px;
  float: right;
}

p.se_so {
  margin-top: 0;
  margin-bottom: 0;
  color: rgb(0, 162, 255);
  font-size: 13px;
}

button.signature-button {
  background-color: #0c1a61;
  transition: transform 0.2s, box-shadow 0.2s;
}

.data-table-trainee .module,
.data-table-trainee .level,
.data-table-trainee .location,
.data-table-trainee .AMTOSS {
  width: 2%;
  /* adjust as needed */
}

.status-tab {
  position: fixed;
  /* or absolute depending on your layout */
  top: 100px;
  /* adjust as needed */
  right: 0;
  cursor: pointer;
  transform: rotate(-90deg);
  transform-origin: top right;
  background-color: #c00;
  color: white;
  padding: 10px;
  z-index: 100;
  /* make sure it's above other elements */
  transition: transform 0.3s ease-in-out;
}

.status-tab:hover {
  background-color: #a00;
  /* slightly darker on hover for visual feedback */
}

.status-content {
  display: none;
  position: absolute;
  right: 100%;
  /* Align with the rotated tab */
  top: 0;
  background-color: #fff;
  border: 1px solid #c00;
  padding: 10px;
  width: 200px;
  /* set a fixed width */
}

.status-tab.open .status-content {
  display: block;
  /* show the content when the tab is open */
}

.status-label {
  writing-mode: vertical-rl;
  text-orientation: upright;
}
</style>
import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import store from './store';
import axios from 'axios';

// Quill Editor
import { quillEditor } from 'vue3-quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

// V-Calendar
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';

//import 'bootstrap/dist/css/bootstrap.min.css';


// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas);

const app = createApp(App);

// Axios configuration
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
app.config.globalProperties.$http = axios;

// V-Calendar setup
app.use(setupCalendar, {});
app.component('VCalendar', Calendar);
app.component('VDatePicker', DatePicker);

// Quill Editor
app.use(quillEditor);

// Font Awesome Icon
app.component('font-awesome-icon', FontAwesomeIcon);

// Router and Vuex Store
app.use(router);
app.use(store);

// Inactivity Timeout Setup
const inactivityTimeout = 15 * 60 * 1000; // 15 minutes
let inactivityTimer = null;

function resetInactivityTimer() {
  if (inactivityTimer) {
    clearTimeout(inactivityTimer);
  }
  inactivityTimer = setTimeout(() => router.replace('/timeout'), inactivityTimeout);
}

// Global Navigation Guards
router.beforeEach((to, from, next) => {
  if (to.path !== '/timeout') {
    resetInactivityTimer();
  }
  next();
});

// Event Listeners for Activity
document.addEventListener('click', resetInactivityTimer);
document.addEventListener('keydown', resetInactivityTimer);

// Mount the Vue instance
app.mount('#app');

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}


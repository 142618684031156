<!-- <template>
  <div id="app">
    <Header />
    <div style="text-align:left; margin-left:1%">
      <h1>TASKS : </h1>
    </div>
    <div class="dropdown">
      <button @click="toggleDropdown" style="width:190px; background-color:rgb(166, 190, 166)">Show/Hide Columns</button>
      <div class="dropdown-menu" :class="{ 'dropdown-menu-active': showDropdown }">
        <div>
          <input type="checkbox" id="selectAll" @change="selectAllColumns($event.target.checked)">
          <label for="selectAll">Select All</label>
        </div>
        <div v-for="column in allColumns" :key="column">
          <input type="checkbox" :id="column" v-model="selectedColumns" :value="column">
          <label :for="column">{{ column }}</label>
        </div>
      </div>
    </div>
    <table class="devTable">
      <tr>
        <th v-for="column in allColumns" :key="column" v-show="selectedColumns.includes(column)">
          {{ column }}
        </th>
      </tr>
      <tr v-for="data in sampleData" :key="data.task_id">
        <td v-for="column in allColumns" :key="column" v-show="selectedColumns.includes(column)">{{ data[column] }}</td>
      </tr>
    </table>
  </div>
</template>


<script>
import Header from './Examiner/Header.vue';
export default {
  components: {
    Header,
  },
  data() {
    return {
      allColumns: ['task_id', 'task_no', 'ratings', 'module', 'chapt_no', 'location', 'area_id', 'AMTOSS', 'activity', 'prod_id', 'category', 'level', 'is_exam', 'being_assessed', 'is_assessed'],
      selectedColumns: ['task_id', 'task_no', 'ratings', 'module', 'chapt_no'], // default selected columns
      sampleData: [
        { task_id: 1, task_no: 206, ratings: 2, module: '7.13', chapt_no: 5, location: 'AC', area_id: 'RIG', AMTOSS: 'A', activity: 'Demonstrate how the AMM is used if adjusting a control cable.', prod_id: 1, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 2, task_no: 70, ratings: 4, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'GVC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when inspecting components', prod_id: 2, category: 'B2', level: 2, is_exam: 1, being_assessed: false, is_assessed: true },
        { task_id: 3, task_no: 71, ratings: 5, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'DDC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when removing / disassembling components. Component: [____________]', prod_id: 3, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 4, task_no: 206, ratings: 2, module: '7.13', chapt_no: 5, location: 'AC', area_id: 'RIG', AMTOSS: 'A', activity: 'Demonstrate how the AMM is used if adjusting a control cable.', prod_id: 1, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 5, task_no: 70, ratings: 4, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'GVC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when inspecting components', prod_id: 2, category: 'B2', level: 2, is_exam: 1, being_assessed: false, is_assessed: true },
        { task_id: 6, task_no: 71, ratings: 5, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'DDC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when removing / disassembling components. Component: [____________]', prod_id: 3, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 7, task_no: 206, ratings: 2, module: '7.13', chapt_no: 5, location: 'AC', area_id: 'RIG', AMTOSS: 'A', activity: 'Demonstrate how the AMM is used if adjusting a control cable.', prod_id: 1, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 8, task_no: 70, ratings: 4, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'GVC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when inspecting components', prod_id: 2, category: 'B2', level: 2, is_exam: 1, being_assessed: false, is_assessed: true },
        { task_id: 9, task_no: 71, ratings: 5, module: '7.18', chapt_no: 5, location: 'AC', area_id: 'DDC', AMTOSS: 'A', activity: 'Demonstrate the use of the IPC and AMM manuals when removing / disassembling components. Component: [____________]', prod_id: 3, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
        { task_id: 10, task_no: 206, ratings: 2, module: '7.13', chapt_no: 5, location: 'AC', area_id: 'RIG', AMTOSS: 'A', activity: 'Demonstrate how the AMM is used if adjusting a control cable.', prod_id: 1, category: 'B1', level: 2, is_exam: 0, being_assessed: true, is_assessed: false },
      ],
      showDropdown: false
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectAllColumns(checked) {
      if (checked) {
        this.selectedColumns = [...this.allColumns];
      } else {
        this.selectedColumns = [];
      }
      this.savePreferences();
    },
  },

  watch: {
    selectedColumns() {
      this.savePreferences(); // Save when selectedColumns changes
    }
  }
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
  margin-top: -15%;
  margin-left: 60%;
}

.dropdown-menu {
  display: none;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 1;
  position: absolute;
  margin-left: 110px;
}

/* Display the dropdown menu when showDropdown is true */
.dropdown-menu-active {
  display: block;
}

table {
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
}

.devTable {
  width: 90%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-collapse: collapse;
  margin-top: 0;
  align-items: left;
  margin-bottom: 30px;
  margin-top: 1%;
  margin-left: 2%;
}
</style> -->

<template>
  <div class="drag-areas">
    <div class="dragArea">
      <draggable v-model="list1" group="items" @end="onEnd">
        <template #item="{element}">
          <div>{{ element.name }}</div>
        </template>
      </draggable>
    </div>

    <div class="dragArea">
      <draggable v-model="list2" group="items" @end="onEnd">
        <template #item="{element}">
          <div>{{ element.name }}</div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  setup() {
    const list1 = ref(Array.from({ length: 10 }, (_, i) => ({ name: `Item ${i + 1}` })));
    const list2 = ref([]);

    const onEnd = () => {
      console.log('Drag ended');
    };

    return {
      list1,
      list2,
      onEnd,
    };
  },
};
</script>


<style>
.drag-areas {
  display: flex; /* Aligns children (dragArea) in a row */
  justify-content: space-around; /* Spaces out the children evenly */
  gap: 20px; /* Adds space between the two drag areas */
}

.dragArea {
  border: 2px solid #007bff; /* Visible border for each drag area */
  padding: 10px;
  width: 40%; /* Adjust width as needed */
  min-height: 200px; /* Minimum height to make empty areas visible */
  display: flex;
  flex-direction: column; /* Aligns items vertically */
  gap: 10px; /* Adds space between items */
  overflow-y: auto; /* Enables scrolling for long lists */
}

.dragArea > div {
  padding: 5px;
  border: 1px solid #ccc; /* Border for individual items */
  background-color: #f9f9f9; /* Light background for items */
}
</style>

<template>
    <div v-if="showQuillEditor" class="modal-overlay" @click="saveContent()">
        <div class="modal-content" @click.stop>
            <h5>Task ID: {{ displayTaskId }}</h5> <!-- Add this line -->
            <quill-editor v-model:value="state.content" :options="state.editorOption" 
                :disabled="state.disabled" @ready="onEditorReady($event)" 
                @change="onEditorChange($event)" />
            <button class="closeButton" @click="saveContent()">Close</button>
        </div>
    </div>
</template>

  
<script>
import { reactive, watch } from 'vue'

export default {
    name: 'App',
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        initialContent: {
            type: [String],
            required: true
        },
        displayTaskId: String
    },
    data() {
        return {
            showQuillEditor: true,
        };
    },
    mounted() {
        console.log(this.initialContent)
    },
    watch: {
        content(newValue) {
            this.$emit('input', newValue);
        }
    },
    setup(props) {

        watch(() => props.initialContent, newValue => {
            state.content = newValue;
        });
        const state = reactive({
            theme: 'snow',
            content: props.initialContent,
            fonts: ['monospace'],
            editorOption: {
                placeholder: 'Enter your notes',
                modules: {
                    toolbar: [
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists
                    ]
                },
            },
            disabled: false

        })
        const onEditorReady = (quill) => {
            console.log('editor ready!', quill)
            adjustEditorHeight();
        }
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text)
            state.content = html
        }
        const adjustEditorHeight = () => {
            const editorElement = document.querySelector('.ql-editor');
            const toolbar = document.querySelector('.ql-toolbar');
            const toolbarButtons = document.querySelectorAll('.ql-toolbar .ql-formats'); // select all button groups inside the toolbar
            if (editorElement) {
                editorElement.style.height = '200px';
                editorElement.style.fontFamily = 'monospace';
            }
            if (toolbar) {
                toolbar.style.height = '40px';  // You might need to adjust this value to fit all buttons
                toolbar.style.minHeight = '30px';  // Also set the minHeight to ensure no overflow
            }
            if (toolbarButtons) {
                toolbarButtons.forEach(btnGroup => {
                    btnGroup.style.margin = '0 2px';  // Reduce margin around button groups
                });
            }
        }

        return {
            state,
            onEditorReady,
            onEditorChange,
        }
    },
    methods: {
        saveContent() {
            this.$emit('saveNotes', { taskId: this.taskId, content: this.state.content });
        }
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Make sure it's on top */
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
}

.ql-toolbar button {
    height: 25px;
    /* Adjust the button height as needed */
    width: 25px;
    /* Adjust the button width as needed */
}

.closeButton {
    margin-bottom: 1px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    width: 100%;
    margin-left: 1px;
    padding: 1px 3px;
}
</style>
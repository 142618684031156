<template>
    <div class="container-fluid navbar-expand-sm custom-navbar navbar">
        <!-- Toggle button for smaller screens -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Links and Logo -->
        <div class="collapse navbar-collapse" id="navbarContent">
            <!-- Logo -->
            <a href="#"><img src="../Examiner/ASTechLogo.png" alt="Logo" width="120" height="50"></a>

            <div class="user-details">
                <span class="navbar-text">
                    <div class="user-info">
                        <div>Logged in as: {{ username }}</div>
                        <div>Role: {{ role }}</div>
                    </div>
                </span>
            </div>
            <a  href="#"><img class="client_logo" src="../Examiner/Atlantic_logo.png" alt="Logo" width="120"
                height="40"></a>
        </div>
    </div>

    <ul class="navbar-nav">
        <li class="nav-item">
            <router-link to="/trainee-home" class="nav-link-examiner" id="dashboard"
                v-bind:class="{ active: activeLink === 'dashboard' }" @click="setActiveLink('home')">Home
            </router-link>
        </li>
        <li class="nav-item">
            <router-link to="/" class="nav-link-examiner" id="logout" v-bind:class="{ active: activeLink === 'logout' }"
                @click="setActiveLink('logout')" style="color: red;">Logout
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'PageHeader',
    data() {
        return {
            username: '',
            userRole: '',
            activeLink: '',
        };
    },
    methods: {
        logout() {
            localStorage.clear();
            window.addEventListener('popstate', () => {
                location.reload(); // Reload the page when the back button is clicked
            });
            this.$router.push({ name: 'LoginPage' });
        },
        setActiveLink(link) {
            if (this.activeLink !== link) {
                this.activeLink = link;
            } else {
                this.activeLink = '';
            }
        },
    },
    mounted() {
        const storedUsername = localStorage.getItem('username');
        const storedRole = localStorage.getItem('role');
        if (storedUsername && storedRole) {
            this.username = storedUsername;
            this.role = storedRole;
        }
    },
}
</script>

<style scoped>
.navbar-collapse {
    display: flex;
    align-items: center;
    height: 100%;

}

.navbar-nav {
    position: sticky;
    top: -1px;
    display: flex;
    width: 100%;
    flex-direction: row; 
    border-bottom: 1px solid #acacac;
    background: linear-gradient(to top, rgb(226, 226, 226), #FFFFFF);
    z-index: 1000;
}

.trainee-navbar-nav .trainee-navbar-nav {
    align-items: center;
    height: 100%;

}

.navbar {
    align-items: center;

    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.container-fluid {
    background: linear-gradient(to right, #87B4FA, #FFFFFF);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 1px;
    padding-top: 1px;
    margin-top: 0 !important;
    box-shadow: 0 4px 10px rgba(70, 52, 52, 0.2);
    z-index: 1000;
    border-bottom: 1px solid #acacac;
}

span {
    margin-top: 0px !important;
    border: none !important;
}

.custom-brand {
    padding: 0 !important;
    /* This is to reduce the padding around the logo */
}

.client_logo {
    margin-right: 10px;
    margin-left: 10px;
}

.user-details {
    margin-top: 0 !important;
    border: none;
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.user-info {
    color: #000000;
    margin-right: 10px;
    font-size: 13px;
    min-width: 150px;
}

.btn {
    margin-right: 10px;
}

.user-details .btn {
    margin-top: 10px;
}

.nav-link-examiner {
    color: black;
    /* Remove text decoration (underline) and add button-like styles */
    text-decoration: none;
    padding: 0px 10px;
    font-size: 14px;
    border-right: 1px solid #bdbdbd;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
}

.nav-link-examiner:hover {
    background-color: #ffffff;
    color: black
        /* moves the link a bit down when hovered */
}

.nav-link-examiner#dashboard {
    margin-left: 20px;
}

.btn-primary {
    margin-bottom: 0px;
}
</style>
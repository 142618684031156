<template>
    <div class="Task-modal-overlay" @click="close">
        <div class="Task-modal-content">
                <div class="Task-modal-header">
        <div class="Task-modal-dialog" @click.stop>
                    <h5 style="padding: 10px;" class="Task-modal-title">Progress of Trainee (UIN: 165273)</h5>
                </div>   
            </div>
            <div>
              <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: currentTab === 'chapters' }"
                                @click.stop="currentTab = 'chapters'">Chapters</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: currentTab === 'areas' }"
                            @click.stop="currentTab = 'areas'">Areas</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: currentTab === 'AMTOSS' }"
                            @click.stop="currentTab = 'AMTOSS'">AMTOSS</a>
                        </li>
                    </ul> 
        <table class="data-table" v-if="currentTab === 'chapters'">

            <thead>
                <th style="width: 400px;"></th>
                <th>Total Tasks</th>
                <th>Completed</th>
                <th>Enabled</th>
                <th>Outstanding</th>
                <th>Percentage of total Complete</th>
            </thead>
        <tbody>
          <tr>
            <td><strong>Overall</strong></td>
            <td>47</td>
            <td>4</td>
            <td>6</td>
            <td>2</td>
            <td>8.51%</td>
          </tr>
          <tr>
            <td><strong>Ch 0: General</strong></td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
            <td>1</td>
            <td>66.67%</td>
          </tr>
          <tr>
            <td><strong>Ch 26: Fire Protection</strong></td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>100%</td>
          </tr>
          <tr>
            <td><strong>Ch 28: Fuel</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>Ch 30: Ice and Rain Protection</strong></td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 36: Pneumatic</strong></td>
            <td>7</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 71: Powerplant</strong></td>
            <td>6</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 72: Turbine/Turboprop Engines</strong></td>
            <td>1</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 73: Engine Fuel Control</strong></td>
            <td>3</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 74: Engine Ignition</strong></td>
            <td>4</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 75: Engine Air</strong></td>
            <td>3</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 76: Engine Control</strong></td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 77: Engine Indicating</strong></td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 79: Engine Oil</strong></td>
            <td>2</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
          <tr>
            <td><strong>Ch 80: Engine Starting</strong></td>
            <td>4</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0%</td>
          </tr>
        </tbody>
      </table>
      <table class="data-table" v-if="currentTab === 'areas'">
            <thead>
                <th style="width: 400px;"></th>
                <th>Total Tasks</th>
                <th>Completed</th>
                <th>Enabled</th>
                <th>Outstanding</th>
                <th>Percentage of total Complete</th>
            </thead>
        <tbody>
          <tr>
            <td><strong>Overall</strong></td>
            <td>47</td>
            <td>4</td>
            <td>6</td>
            <td>2</td>
            <td>8.51%</td>
          </tr>
          <tr>
            <td><strong>F/C</strong></td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
            <td>1</td>
            <td>66.67%</td>
          </tr>
          <tr>
            <td><strong>ENG</strong></td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>100%</td>
          </tr>
          <tr>
            <td><strong>L/G</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>G/F</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>HYD</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>FUS</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>CAB</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>E&A</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
        </tbody>
      </table>
      <table class="data-table" v-if="currentTab === 'AMTOSS'">
            <thead>
                <th style="width: 400px;"></th>
                <th>Total Tasks</th>
                <th>Completed</th>
                <th>Enabled</th>
                <th>Outstanding</th>
                <th>Percentage of total Complete</th>
            </thead>
        <tbody>
          <tr>
            <td><strong>Overall</strong></td>
            <td>47</td>
            <td>4</td>
            <td>6</td>
            <td>2</td>
            <td>8.51%</td>
          </tr>
          <tr>
            <td><strong>RIG</strong></td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
            <td>1</td>
            <td>66.67%</td>
          </tr>
          <tr>
            <td><strong>GVC</strong></td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>100%</td>
          </tr>
          <tr>
            <td><strong>DDC</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>TS</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>LOC</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>AHA</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>SER</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>ADJ</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
          <tr>
            <td><strong>LUB</strong></td>
            <td>2</td>
            <td>1</td>
            <td>1</td>
            <td>0</td>
            <td>50%</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
        </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        taskId: {
            type: [String, Number],
            required: true
        },
        logbookId: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            showTaskLongFormModal: true,
            taskData: [],
            entryData: [],
            currentTab: 'chapters',
        };
    },

    methods: {
        close() {
            this.$emit('close');
        },  
        async getTaskData(taskId) {
            const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
            console.log(taskId);
            try {
                // Assuming you have an API endpoint to fetch task data
                const response = await axios.get(`/api/tasks/${taskId}`, { headers });
                const data = response.data;

                this.taskData = data;

                console.log(data);

               
            } catch (error) {
                console.error('Error fetching task data:', error);
                // Handle errors as needed
            }
        },

    },
    async mounted() {
    this.getTaskData(this.taskId);

  },
}


</script>

<style scoped>
.Task-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(146, 146, 146, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Task-modal-content {
    margin-top: 40px;
    background-color: rgb(239, 244, 255);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    width: 1200px;
    max-height: 85vh; /* You can adjust the maximum height as needed */
    min-height: 85vh;
    overflow-y: auto;
    border: 2px solid rgb(83, 83, 83);
    margin-top: 80px;
}

.Task-modal-title {
    margin-bottom: 10px;
    float: left;
    margin-left: 20px;
    color: black;
}

.data-table {
    border-collapse: collapse;
    background-color: rgb(253, 253, 255);
    border: 1px solid grey;
    box-shadow: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.data-table td, .data-table th {
    border: none;
    padding: 14px;
    width: 250px;
    font-size: 20px;
}
</style>
import { createStore } from 'vuex'

export default createStore({
  state: {
    selectedProduct: null,
    issues: [],
    importedTasks: [],
    importedTrainees: [],
    fileName: '',
    skippedTasks: [],
    skippedTrainees: [],
  },
  mutations: {
    SET_SELECTED_PRODUCT(state, product) {
      state.selectedProduct = product;
    },
    SET_ISSUES(state, issues) {
      state.issues = issues;
    },
    SET_IMPORTED_TASKS(state, tasks) {
      state.importedTasks = tasks;
    },
    SET_IMPORTED_TRAINEES(state, trainees) {
      state.importedTrainees = trainees;
    },
    SET_FILE_NAME(state, name) {
      state.fileName = name;
    },
    SET_SKIPPED_TASKS(state, skippedTasks) {
      state.skippedTasks = skippedTasks;
    },
    SET_SKIPPED_TRAINEES(state, skippedTrainees) {
      state.skippedTrainees = skippedTrainees;
    },
  },
  actions: {
    selectProduct({ commit }, product) {
      commit('SET_SELECTED_PRODUCT', product);
      // ... maybe other actions like API calls
    },
    setIssues({ commit }, issues) {
      commit('SET_ISSUES', issues);
    },
    setImportedTasks({ commit }, tasks) {
      commit('SET_IMPORTED_TASKS', tasks);
    },
    setImportedTrainees({ commit }, trainees) {
      commit('SET_IMPORTED_TRAINEES', trainees);
    },
    setFileName({ commit }, name) {
      commit('SET_FILE_NAME', name);
    },
    setSkippedTasks({ commit }, skippedTasks) {
      commit('SET_SKIPPED_TASKS', skippedTasks);
    },
    setSkippedTrainees({ commit }, skippedTrainees) {
      commit('SET_SKIPPED_TRAINEES', skippedTrainees);
    }
  },
  getters: {
    selectedProduct: state => state.selectedProduct,
    issues: state => state.issues,
    tasks: state => state.tasks,
    trainees: state => state.trainees,
    name: state => state.name,
    skippedTasks: state => state.skippedTasks,
    skippedTrainees: state => state.skippedTrainees,
  }
})

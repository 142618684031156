<template>
  <div ref="editorContainer" class="quill-editor-container"></div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import Quill from 'quill';


// Ref for the editor container
const editorContainer = ref(null);
// Predefined content
const predefinedContent = `<strong>Troubleshooting: </strong> State & note the appropriate procedure
  <strong>MEL Procedure: </strong> If spare parts not available - is the aircraft dispatchable. If applicable, demonstrate the appropriate procedure
  <strong>Location: </strong> Find & state the location of all related controls and components
  <strong>Removal/Installation: </strong> Identify components to be changed. State the appropriate repair/removal/installation procedure
  State & note the appropriate handbook procedure
  <strong>Safety Aspects: </strong> State all mandatory safety precautions and procedures
  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  <b>Question:</b> Explain the Loss of the Detection by Engine 1 Loop A under the following headings:
  <em>System Integration:</em>
  <br>
  <em>Location of Components:</em>
  <br>
  <em>Technical Publications Reference:</em>
  <br>
  <em>Perform Maintenance Actions:</em>
  <br>
  <em>Possible MEL Deferral</em>
  <br>
  <em>Aircraft final/close up</em>
  <br>
`;

// Initialize Quill on component mount
onMounted(() => {
  const quill = new Quill(editorContainer.value, {
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ]
    }
  });

  // Function to adjust editor height and styles
  const adjustEditorHeight = () => {
    const toolbar = document.querySelector('.ql-toolbar');
    const toolbarButtons = document.querySelectorAll('.ql-toolbar .ql-formats'); // select all button groups inside the toolbar
    if (toolbar) {
      toolbar.style.height = '70px';
    }
    if (toolbarButtons) {
      toolbarButtons.forEach(btnGroup => {
        btnGroup.style.margin = '0 2px';  // Reduce margin around button groups
      });
    }
  }

  // Set the predefined content
  quill.root.innerHTML = predefinedContent;

  // Call adjustEditorHeight after Quill is initialized
  adjustEditorHeight();

  // If there's a need to expose onEditorReady, it should be called or assigned correctly
  const onEditorReady = () => {
    console.log('editor ready!', quill);
    // Perform any additional setup or adjustments if necessary
  }

  // If you need to expose onEditorReady, you can return it here
  return { onEditorReady };
});

</script>

<style scoped>
</style>
